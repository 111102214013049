var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-2 ml-0"},[(_vm.showAlert)?_c('SuccessAlert',{staticClass:"alert",attrs:{"data-cy":"alert","type":_vm.alertType,"message":_vm.alertMessage}}):_vm._e()],1),_c('v-data-table',{staticClass:"ml-5",attrs:{"headers":_vm.headers,"items":_vm.getTable,"items-per-page":5},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"delete","color":"primary","icon":"","x-small":"","no-data-text":"No Tokens created yet."},on:{"click":function($event){return _vm.deleteToken(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdDate).toLocaleString()))])]}},{key:"item.expireDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.expireDate).toLocaleString()))])]}},{key:"item.permissions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"data-cy":"show_permissions","color":"hqsOrange"},on:{"click":function($event){return _vm.showPermission(item)}}},[_vm._v("mdi-magnify")])]}}],null,true)}),_c('PermissionsDialog',{ref:"info",attrs:{"items":_vm.permissions,"toolbarTitle":_vm.toolbarTitle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }