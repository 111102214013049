<template>
  <div>
    <v-row class="mb-2 ml-0">
      <SuccessAlert
        data-cy="alert"
        class="alert"
        v-if="showAlert"
        v-bind:type="alertType"
        v-bind:message="alertMessage"
      />
    </v-row>
    <v-data-table
      class="ml-5"
      :headers="headers"
      :items="getTable"
      :items-per-page="5"
    >
      <template v-slot:item.actions="{ item }">
        <!-- delete table action button -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="delete"
              color="primary"
              v-bind="attrs"
              v-on="on"
              icon
              x-small
              no-data-text="No Tokens created yet."
              @click="deleteToken(item)"
            >
              <v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <!-- date conversion -->
      <template v-slot:[`item.createdDate`]="{ item }">
        <span>{{ new Date(item.createdDate).toLocaleString() }}</span>
      </template>
      <template v-slot:[`item.expireDate`]="{ item }">
        <span>{{ new Date(item.expireDate).toLocaleString() }}</span>
      </template>
      <!-- icon button for permissions display -->
      <template v-slot:[`item.permissions`]="{ item }">
        <v-icon
          data-cy="show_permissions"
          @click="showPermission(item)"
          color="hqsOrange"
          >mdi-magnify</v-icon
        >
      </template>
    </v-data-table>
    <PermissionsDialog
      ref="info"
      :items="permissions"
      :toolbarTitle="toolbarTitle"
    />
  </div>
</template>

<script>
const PermissionsDialog = () => import('./PermissionsDialog.vue')
const SuccessAlert = () => import('../general/SuccessAlert.vue')
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  components: { SuccessAlert, PermissionsDialog },

  data() {
    return {
      /* PermissionsDialog */
      permissions: [],
      toolbarTitle: 'Rights',
      /* token tabel */
      headers: [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Created at', value: 'createdDate', sortable: false },
        { text: 'Expires at', value: 'expireDate', sortable: false },
        { text: 'Rights', value: 'permissions', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' },
      ],
      /* SuccessAlert */
      showAlert: false,
      alertType: '',
      alertMessage: '',
    }
  },

  methods: {
    ...mapActions('tokens', ['delete', 'load']),

    showPermission(item) {
      this.permissions = item.permissions
      this.$refs.info.openDialog()
    },

    async deleteToken(item) {
      try {
        await this.delete(this.owner + '.' + item.name)
        await this.load()
        this.prepareAlert(
          'Your token was deleted successfully',
          'success',
        )
      } catch (error) {
        let errorMessage = JSON.stringify(error.response.data.message, null, 4)
        this.prepareAlert(
          'Your token could not be deleted: ' + errorMessage,
          'error',
        )
      }
    },

    prepareAlert(message, type) {
      this.alertType = type
      this.alertMessage = message
      this.showAlert = true
      if (type === 'success') {
        window.setTimeout(() => {
          this.showAlert = false
        }, this.alertTimeout)
      }
    },
  },

  computed: {
    ...mapGetters('tokens', ['getTable']),

    ...mapState('tokens', {
        owner: (state) => state.owner
    }),

   ...mapState('backend', {
      alertTimeout: (state) => state.alertTimeout,
    }),
  },
}
</script>

<style lang="scss" scoped></style>
